import { useState, useEffect } from "react";
import "./App.css";
import man from "./man.png";
import curve from "./curve.png";
// https://www.youtube.com/watch?v=Zr2-0BJ0KXo
function App() {
  const [timeLeft, handleTimeLeft] = useState({
    days: 0,
    hour: 0,
    minute: 0,
    seconds: 0,
  });
  const findDayHourMinuteSeconds = () => {
    const nowDate = new Date().getTime();
    const dDay = new Date("Jan 1, 2024 00:00:00").getTime();
    const gap = dDay - nowDate;
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const d = Math.floor(gap / day);
    const h = Math.floor((gap % day) / hour);
    const m = Math.floor((gap % hour) / minute);
    const s = Math.floor((gap % minute) / second);
    handleTimeLeft({
      days: d,
      hour: h,
      minute: m,
      seconds: s,
    });
  };

  useEffect(() => {
    setInterval(() => {
      findDayHourMinuteSeconds();
    }, 1000);
  }, []);
  return (
    <div>
      <header>
        <a href="#" className="groovydudslogo">
          GROOVYDUDS
        </a>
        {/* <nav>
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
          </ul>
        </nav> */}
      </header>
      <section>
        <img src={curve} className="groovyCurve" />
        <div class="contentBox">
          <h2>Coming Soon</h2>
          <p>Kursi ki peti bandh lijiye</p>
          <div class="countdown">
            <div className="time">
              <div id="day">{timeLeft.days}</div>
              <span>Days</span>
            </div>
            <div className="time">
              <div id="hour">{timeLeft.hour}</div>
              <span>Hours</span>
            </div>
            <div className="time">
              <div id="minute">{timeLeft.minute}</div>
              <span>Minutes</span>
            </div>
            <div className="time">
              <div id="second">{timeLeft.seconds}</div>
              <span>Seconds</span>
            </div>
          </div>
          {/* <a href="#">Notify Me</a> */}
        </div>
        <div className="groovyDudsManBox">
          <img src={man} />
        </div>
      </section>
      <div className="footer">
        <p>Copyright © 2023 – Groovyduds.in | All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default App;
